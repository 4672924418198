@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 40px; 
  }
  .titleBack {
    font-size:10px
  }
}
#radio-waves {
  position: relative;
  width: 100%;
  height: 100vh;
  background: black;
  overflow: hidden;
}

.wave {
  position: absolute;
  border: 2px solid white;
  border-radius: 50%;
  opacity: 0.5;
  animation: pulseWave 2s infinite;
  pointer-events: none; /* Ignore mouse events */
}

@keyframes pulseWave {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

/* Example sizes - adjust as needed */
#wave1 { width: 100px; height: 100px; top: 50%; left: 50%; margin-top: -50px; margin-left: -50px; }
#wave2 { width: 150px; height: 150px; top: 50%; left: 50%; margin-top: -75px; margin-left: -75px; animation-delay: 1s; }
#wave3 { width: 200px; height: 200px; top: 50%; left: 50%; margin-top: -100px; margin-left: -100px; animation-delay: 2s; }
.chatbot-container {
  max-width: 350px; /* Adjust based on your preference */
  background: #000000; /* Dark theme background */
  border-radius: 15px;
  padding: 15px;
  margin-top: 5px; /* Adjust as needed */
  box-shadow: 0 4px 8px rgba(0,0,0,0.5);
}

.Home b {
  font-size: 80px;
  background: linear-gradient(90deg, #ff0000, #00ff00, #0000ff); /* Example gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* Prevent block-level styles */
  display: inline-block; /* Change to 'inline-block' if needed */
}
.titleBack {
  position:relative;
  box-shadow:20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius:15px;
  background:(255,255,255,.1);
  overflow:hidden;
  display:flex;
  justify-content:center;
  align-items: center;
  border-top:1px solid rgba(255, 255, 255, 0.5);
  border-left:1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  padding: 20px;
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}
.titleBack :hover {  transform: translateY(-.5px);
}

.infoCard {
  position:relative;
  box-shadow:20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius:15px;
  background:(255,255,255,0.1);
  overflow:hidden;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  border-bottom:1px solid rgba(255, 255, 255, 0.5);
  border-right:1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  padding: 10px;
}

.infoCard .textOne {
  position: relative;
  transition: .5s; /* Smooth transition for hover effects */
  align-self: center; /* Center the image */
  align-content: center;
  overflow: hidden;
  transform: translateY(0);
}
.infoCard:hover .textOne {
  transform: translateX(-100%); /* Move Left */
  padding: 0px;
}
.infoCard .textTwo {
  padding:0px;
  text-align:center;
  transform: translateY(0%);
  opacity: 0;
  transition: .5s;
  visibility: hidden;
}
.infoCard:hover .textTwo{
  transform: translateY(-150px);
  z-index: 2;
  opacity:1;
  visibility: visible;
}
.infoCard .textTwo h3{
  margin-top: 20px;
  font-size: 1.8em;
  color:white;
  z-index:1;
}
.infoCard .textTwo p {
  font-size: 1em;
  color:white;
  font-weight: 300;
}


.infoCardLeft {
  position:relative;
  box-shadow:20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius:15px;
  background:(255,255,255,0.1);
  overflow:hidden;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  border-top:1px solid rgba(255, 255, 255, 0.5);
  border-left:1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  padding: 20px;
}

.infoCardLeft .textOne {
  position: relative;
  transition: .5s; /* Smooth transition for hover effects */
  align-self: center; /* Center the image */
  align-content: center;
  overflow: hidden;

  transform: translateY(0);
}
.infoCardLeft:hover .textOne {
  transform: translateX(-100%); /* Move Left */
}
.infoCardLeft .textTwo {
  padding:20px;
  text-align:center;
  transform: translateY(0%);
  opacity: 0;
  transition: .5s;
}
.infoCardLeft:hover .textTwo{
  transform: translateY(-150px);
  z-index: 2;
  opacity:1;
}
.infoCardLeft .textTwo h3{
  margin-top: 20px;
  font-size: 1.8em;
  color:white;
  z-index:1;
}
.infoCardLeft .textTwo p {
  font-size: 1em;
  color:white;
  font-weight: 300;
}



.mlCard {
  position:relative;
  box-shadow:20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius:15px;
  background:(255,255,255,0.1);
  overflow:hidden;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  border-top:1px solid rgba(255, 255, 255, 0.5);
  border-left:1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  padding: 10px;
}

.learnMore {
  color: #ffffff;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 00);
  border-color: rgba(0, 0, 0, 0);
  font-size: 20px;
}

.mlCard .img {
  position: relative;
  transition: .5s; 
  align-self: center;
  align-content: center;
  overflow: hidden;

  transform: translateY(0);
}
.cardTitle {
  color: white;
  align-self: center;
  font-weight: bolder;
  margin-bottom: 20px; 
}
.contentContainer {
  top: 60px; 
  left: 0;
  width: 100%;
  height: calc(100% - 60px); 
  overflow: hidden; 
  
}
.mlCard:hover .img {
  transform: translateY(-100%); /* Move up */
}
.mlCard .content {
  padding:20px;
  text-align:center;
  transform: translateY(00%);
  opacity: 0;
  transition: .5s;
}
.mlCard:hover .content{
  transform: translateY(-300px);
  z-index: 2;
  opacity:1;
}
.mlCard .content h3{
  margin-top: 20px;
  font-size: 1.8em;
  color:white;
  z-index:1;
}
.mlCard .content p {
  font-size: 1em;
  color:white;
  font-weight: 300;
}
.messages {
  max-height: 500px;
  overflow-y: auto;
}

.message-bubble {
  background: #000000; 
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  margin: 50px;
}


.hero-section {
  position: relative;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  overflow: hidden;
}
.hero-section :hover {
  align-items: left;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.scroll-section {
  transition: transform 0.5s ease;
}

.scroll-section.active {
  transform: translateY(-50px);
}
.features-section {
  background-color: rgb(0, 0, 0);
  padding: 60px 0;
  transition: transform 0.5s ease;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* App.css */
body {
  font-family: 'Arial', sans-serif;
  background: #f4f4f4;
  margin: 0;
  padding: 0;
}
.feature-card {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  
}

.feature-card.hovered {
  background-color: #fff;
  transform: translateY(-10px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.feature-card h3 {
  font-size: 24px;
  margin: 10px 0;
}

.feature-card p {
  font-size: 16px;
}

.card-details {
  display: none;
  margin-top: 20px;
  font-size: 14px;
}

.feature-card.hovered .card-details {
  display: block;
}
/* App.css */
.navbar-nav .nav-link {
  color: #000 !important;
  text-decoration-color: #000;
  margin: 0 10px;
  cursor: pointer;
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;  
}
.carousel-item {
  width: 100%; /* Ensure it takes full width */
  height: auto; /* Maintain aspect ratio */
}
.carousel-item img {
  max-height: 400px; /* Set the desired max height */
  object-fit: cover;  /* Ensures the image maintains aspect ratio while filling the space */
  width: 100%;        /* Makes the image responsive */
}
.custom-navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.7s ease; 
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.custom-navbar .scroll-section{
  background-color: rgba(0, 0, 0, 0.75);
}
.navbar-dark .navbar-nav .nav-link.active {
  text-decoration: underline rgba(0, 0, 0, 0.75);
}

.custom-navbar .navbar-brand {
  font-weight: bold;
  color: #000000;
}
.custom-navbar .nav-link:hover {
  color: #000000;
  border-bottom:2px solid gray;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
.custom-navbar .active {
  border-bottom: 2px solid #000000;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

}
.custom-navbar.scrolled .active {
  border-bottom: 2px solid #a0a0a0;
  transition: transform 0.7s ease, box-shadow 0.7s ease, background-color 0.7s ease;
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.custom-navbar.scrolled {
  background-color: rgba(27, 27, 27, 1); 
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
/* ul {
  list-style-type: none; 
  padding-left: 0; 
}

ul li::before {
  content: '• '; 
  color: black; 
  font-weight: bold; 
  display: inline-block; 
  width: 1em; 
  margin-left: -1em; 
}

ul li {
  padding-bottom: 0.5em; /* Adds space between list items */


.custom-navbar.scrolled .navbar-nav .nav-link,
.custom-navbar.scrolled .navbar-brand,
.custom-navbar.scrolled .nav-link.active {
  color: #ffffff !important;
  text-decoration-color: #000;
  margin: 0 10px;
  cursor: pointer;
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.custom-navbar.scrolled .nav-link:hover {
  color: #fff;
  border-bottom: 2px solid gray; 
}


/* Button Styling */
.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 2px;
}

/* Add styling for other sections based on your design */
